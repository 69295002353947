import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import RichText from "../components/richText"
import PageTitle from '../components/PageTitle'
import ImageAndText from '../components/ImageAndText'
import StyledImage from '../components/common/StyledImage'

export const query = graphql`
{
  prismic {
    allPortraits(lang: "en-us") {
      edges {
        node {
          seo_description
          seo_title
          page_title
          bild_und_text {
            portrait_foto
            portrait_foto_text
          }
        }
      }
    }
  }
}
`
export default function Team(props) {

  const portraitData = props.data.prismic.allPortraits.edges[0].node

  let seoTitle = ""

  // Set default values if null
  if (portraitData.seo_title) {
    seoTitle = portraitData.seo_title
  } else {
    seoTitle = "Pachamama Festival" + " | " + "Team"
  }

  const seoDescription = portraitData.seo_description

  const pageTitle = portraitData.page_title;

  // Add a counter for each obj by the index of it
  portraitData.bild_und_text.forEach((object, i) => object.count = i + 1);
  return (
    <Layout seoTitle={seoTitle} seoDescription={seoDescription}>
      <PageTitle pageTitle={pageTitle} />
      {portraitData.bild_und_text.map((person, i) => {
        if (person.portrait_foto) {
          return (
            <ImageAndText
              image={<StyledImage src={person.portrait_foto.url} />}
              text={<RichText render={person.portrait_foto_text} />}
              counter={person.count}
              key={i}
            />
          )
        } else { return null }
      })}
    </Layout>
  )
}

